import api from '@apiInstance';
const endpoint = 'relocation_categories';

export default {
  add(data) {
    return api.post(endpoint, data);
  },
  get(id) {
    return api.fetch(`${endpoint}/${id}`);
  },
  async list(params) {
    return await api.fetch(`/${endpoint}`, { params });
  },
  update(data) {
    return api.patch(endpoint, data);
  },
  delete(id) {
    return api.remove(endpoint, id);
  },
};
